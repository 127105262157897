import React from "react";
import { useAuth } from "../../context/auth";
import { NavLink, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminHeader = () => {
  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    toast.success("Logout Successfully");
    localStorage.removeItem("auth");
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{ backgroundColor: "#49759e", height: "50px" }}
      >
        <div className="container-fluid">
          <span
            className="navbar-brand"
            style={{ color: "white", fontSize: "25px" }}
          >
            POS SYSTEM
          </span>
          {auth?.user ? (
            <span className="navbar-brand" style={{ color: "white" }}>
              {auth?.user?.role === 1 ? (
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to="/admin/dashboard"
                >
                  Admin Dashboard
                </Link>
              ) : (
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to="/dashboard/cashier"
                >
                  Cashier Dashboard
                </Link>
              )}
            </span>
          ) : (
            ""
          )}
          {auth?.user ? (
            <span className="navbar-brand" style={{ color: "white" }}>
              User: {auth?.user?.name}
            </span>
          ) : (
            ""
          )}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {auth?.user ? (
                  <NavLink
                    onClick={handleLogout}
                    to="/login"
                    className="nav-link"
                    style={{ color: "white" }}
                  >
                    Logout
                  </NavLink>
                ) : (
                  <Link
                    to="/login"
                    className="nav-link"
                    style={{ color: "white" }}
                  >
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminHeader;
