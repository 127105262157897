import React from "react";

const CategoryForm = ({
  title,
  handleSubmit,
  value,
  setValue,
  placeholder,
}) => {
  return (
    <>
      <form
        className="row row-cols-lg-auto g-3 align-items-center"
        onSubmit={handleSubmit}
      >
        <div className="col-12">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={placeholder}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default CategoryForm;
