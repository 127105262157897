import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { Modal } from "antd";
import SummaryReport from "../../components/Form/SummaryReport";
import { useNavigate } from "react-router-dom";

const CloseCounter = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const [counterId, setCounterId] = useState("");
  const [initialCash, setInitialCash] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [collectCash, setCollectCash] = useState(0);
  const [collectEwallet, setCollectEwallet] = useState(0);
  const [collectPbb, setCollectPbb] = useState(0);

  const [systemCash, setSystemCash] = useState("");
  const [systemEwallet, setSystemEwallet] = useState("");
  const [systemPbb, setSystemPbb] = useState("");
  const [systemTotal, setSystemTotal] = useState("");

  const [visibleSummaryModal, setVisibleSummaryModal] = useState(false);

  useEffect(() => {
    getClosedCounter();
  }, []);

  // Check if Open Counter
  const getClosedCounter = async () => {
    try {
      const userId = auth?.user?._id;
      const { data } = await axios.post(
        "/api/v1/counter/close-counter/details",
        { userId }
      );
      console.log(data);
      setCounterId(data?._id);
      setStartDate(data?.startdate);
      setEndDate(data?.enddate);
      setInitialCash(data?.initialcash);
      setSystemCash(data?.syscash);
      setSystemEwallet(data?.sysewallet);
      setSystemPbb(data?.syspbb);
      setSystemTotal(data?.syscash + data?.sysewallet + data?.syspbb);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(
        "/api/v1/counter/close-counter/submit",
        {
          counterId,
          collectCash,
          collectEwallet,
          collectPbb,
        }
      );
      navigate("/cashier/dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  const closeSummaryModal = (e) => {
    e.preventDefault();
    setVisibleSummaryModal(false);
  };

  return (
    <Layout title={"Close Counter"}>
      <div className="pt-3 px-3">
        <div className="text-center h4">Close Counter Checkout</div>
        <div className="text-end pb-3">
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => setVisibleSummaryModal(true)}
          >
            View Summary Counter Receipt
          </button>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Collected</th>
              <th className="text-end">System Amount</th>
              <th className="text-end">Variance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-bold">Cash</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={collectCash}
                  onChange={(e) => setCollectCash(e.target.value)}
                />
              </td>
              <td className="text-end">
                {(Math.round(systemCash * 100) / 100).toFixed(2)}
              </td>
              <td className="text-end">
                {systemCash - collectCash !== 0 ? (
                  <span style={{ color: "red" }}>
                    {(
                      Math.round((systemCash - collectCash) * 100) / 100
                    ).toFixed(2)}
                  </span>
                ) : (
                  (Math.round((systemCash - collectCash) * 100) / 100).toFixed(
                    2
                  )
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">eWallet</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={collectEwallet}
                  onChange={(e) => setCollectEwallet(e.target.value)}
                />
              </td>
              <td className="text-end">
                {(Math.round(systemEwallet * 100) / 100).toFixed(2)}
              </td>
              <td className="text-end">
                {systemEwallet - collectEwallet !== 0 ? (
                  <span style={{ color: "red" }}>
                    {(
                      Math.round((systemEwallet - collectEwallet) * 100) / 100
                    ).toFixed(2)}
                  </span>
                ) : (
                  (
                    Math.round((systemEwallet - collectEwallet) * 100) / 100
                  ).toFixed(2)
                )}
              </td>
            </tr>
            <tr>
              <td className="fw-bold">Public Bank</td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={collectPbb}
                  onChange={(e) => setCollectPbb(e.target.value)}
                />
              </td>
              <td className="text-end">
                {(Math.round(systemPbb * 100) / 100).toFixed(2)}
              </td>
              <td className="text-end">
                {systemPbb - collectPbb !== 0 ? (
                  <span style={{ color: "red" }}>
                    {(Math.round((systemPbb - collectPbb) * 100) / 100).toFixed(
                      2
                    )}
                  </span>
                ) : (
                  (Math.round((systemPbb - collectPbb) * 100) / 100).toFixed(2)
                )}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className="fw-bold">Total</td>
              <td></td>
              <td className="text-end fw-bold">
                {(
                  Math.round((systemCash + systemEwallet + systemPbb) * 100) /
                  100
                ).toFixed(2)}
              </td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={4} className="text-end">
                <button
                  type="button"
                  class="btn btn-success"
                  onClick={handleSubmit}
                >
                  Submit & Close Counter
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <Modal
        onCancel={() => setVisibleSummaryModal(false)}
        footer={null}
        open={visibleSummaryModal}
      >
        <SummaryReport
          startDate={startDate}
          endDate={endDate}
          initialCash={initialCash}
          systemTotal={systemTotal}
          closeSummaryModal={closeSummaryModal}
        />
      </Modal>
    </Layout>
  );
};

export default CloseCounter;
