import React from "react";

const SaleProductReport = ({ closeProductReportModal, products }) => {
  return (
    <div>
      <div className="mt-2 text-center h5 shadow-sm p-2 bg-white rounded">
        Product Sale Records
      </div>
      <table className="table mt-2">
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <>
              <tr>
                <td>{product.refcode}</td>
                <td>{product.name}</td>
                <td>{product.quantity}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
      <div className="text-end mt-4">
        <button
          type="submit"
          class="btn btn-outline-danger ms-3"
          onClick={closeProductReportModal}
        >
          <i class="bi bi-x-lg"></i> Cancel
        </button>
      </div>
    </div>
  );
};

export default SaleProductReport;
