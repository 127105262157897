import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import CategoryForm from "../../../components/Form/CategoryForm";
import axios from "axios";

const AdminCategoryList = () => {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);

  // Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/api/v1/category/new-category", {
        name,
      });
      if (data?.success) {
        getAllCategory();
        setName("");
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get All Categories
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/category/all-category");
      if (data?.success) {
        setCategories(data?.categoryList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <Layout title={"Category"}>
      <div className="container-fluid m-3">
        <div className="row">
          <div className="p-3 w-50">
            <CategoryForm
              handleSubmit={handleSubmit}
              value={name}
              setValue={setName}
              placeholder="Enter New Category"
            />
          </div>
          <div className="w-100">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col" className="text-center">
                    Status
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {categories.map((data, key) => (
                  <>
                    <tr key={key}>
                      <td key={data.categoryId}>{data.categoryId}</td>
                      <td>{data.name}</td>
                      <td className="text-center">
                        {data.status === 1 ? (
                          <i
                            class="bi bi-check-lg"
                            style={{ color: "green" }}
                          ></i>
                        ) : (
                          <i class="bi bi-x-lg" style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td className="text-end">
                        <button
                          className="btn btn-outline-primary btn-sm border-0 ms-2"
                          onClick={() => {}}
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminCategoryList;
