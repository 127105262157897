import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const AdminCreateProduct = () => {
  // const [categories, setCategories] = useState([]);
  // const [subcategories, setSubcategories] = useState([]);
  const [name, setName] = useState("");
  const [refcode, setRefcode] = useState("");
  const [ibsn, setIbsn] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");

  // Get All Category
  // const getAllCategory = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_API}/api/v1/category/all-category`
  //     );
  //     if (data?.success) {
  //       setCategories(data?.categoryList);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // Get All Subcategory
  // const getAllSubcategory = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_API}/api/v1/subcategory/all-subcategory`
  //     );
  //     if (data?.success) {
  //       setSubcategories(data?.subcategoryList);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllCategory();
  //   getAllSubcategory();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/api/v1/product/new-product", {
        name,
        refcode,
        ibsn,
        price,
        quantity,
        discount,
        category,
        subcategory,
      });

      if (data?.success) {
        navigate("/admin/products");
      } else {
        console.log(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  return (
    <Layout title={"Create New Product"}>
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-sm-center h-100">
            <div className="col-md-7">
              <div className="text-center my-3">
                <h1 className="fs-4 card-title fw-bold mb-4">
                  Add New Product
                </h1>
              </div>
              <div className="card shadow-lg">
                <div className="card-body p-4">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="name">
                        Name
                      </label>
                      <input
                        id="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control"
                        name="name"
                        required
                      />
                      <div className="invalid-feedback">Name is invalid</div>
                    </div>
                    {/* <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="name">
                        Category
                      </label>
                      <Select
                        onChange={(value) => {
                          setCategory(value);
                        }}
                        bordered={false}
                        size=""
                        showSearch
                        className="form-select mb-3"
                      >
                        {categories?.map((data) => (
                          <Option key={data._id} value={data.categoryId}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                      <div className="invalid-feedback">Name is invalid</div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="name">
                        Subcategory
                      </label>
                      <Select
                        onChange={(value) => {
                          setSubcategory(value);
                        }}
                        bordered={false}
                        size=""
                        showSearch
                        className="form-select mb-3"
                      >
                        {subcategories?.map((data) => (
                          <Option key={data._id} value={data.subcategoryId}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                      <div className="invalid-feedback">Name is invalid</div>
                    </div> */}
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="refcode">
                        Item Code
                      </label>
                      <input
                        id="refcode"
                        value={refcode}
                        onChange={(e) => setRefcode(e.target.value)}
                        type="text"
                        className="form-control"
                        name="refcode"
                        required
                      />
                      <div className="invalid-feedback">
                        Item Code is invalid
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="refcode">
                        IBSN
                      </label>
                      <input
                        id="ibsn"
                        value={ibsn}
                        onChange={(e) => setIbsn(e.target.value)}
                        type="text"
                        className="form-control"
                        name="ibsn"
                      />
                      <div className="invalid-feedback">IBSN is invalid</div>
                    </div>

                    <div className="mb-3">
                      <label className="mb-2 text-muted">Price</label>
                      <input
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        type="number"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">Price is invalid</div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted">Discount</label>
                      <input
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        type="number"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">Price is invalid</div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted">Quantity</label>
                      <input
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        type="number"
                        className="form-control"
                        required
                      />
                      <div className="invalid-feedback">
                        Quantity is invalid
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-outline-primary ms-auto mx-5"
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn btn-outline-secondary ms-auto"
                        onClick={() => navigate("/admin/products")}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="text-center mt-5 text-muted">
                Copyright © 2023 Gospel Book Centre PLT
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AdminCreateProduct;
