import React from "react";

const RefCodePaymentForm = ({
  title,
  handleSubmit,
  value,
  refCodeValue,
  setRefCodeValue,
  closeCashModal,
}) => {
  return (
    <>
      <div className="mt-2 text-center h4 shadow-sm p-3 bg-white rounded">
        {title}
      </div>
      <form className="mt-3 h4">
        <div className="form-group row">
          <label htmlFor="inputPassword" className="col-sm-6 col-form-label">
            Final Total
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={"RM " + (Math.round(value * 100) / 100).toFixed(2)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputPassword" className="col-sm-6 col-form-label">
            Reference Number
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control mt-2 fs-5"
              value={refCodeValue}
              onChange={(e) => setRefCodeValue(e.target.value)}
            />
          </div>
        </div>

        <div className="text-end mt-4">
          <button
            type="submit"
            class="btn btn-outline-success"
            onClick={handleSubmit}
          >
            <i class="bi bi-check-lg"></i> OK
          </button>
          <button
            type="submit"
            class="btn btn-outline-danger ms-3"
            onClick={closeCashModal}
          >
            <i class="bi bi-x-lg"></i> Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default RefCodePaymentForm;
