import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import moment from "moment";
import "../../styles/CashierStyle.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";

const SaleRecords = () => {
  const [auth, setAuth] = useAuth();
  const [records, setRecords] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [showProduct, setShowProduct] = useState(false);
  const [recordId, setRecordId] = useState("");
  const navigate = useNavigate();

  // Get All Sale Records
  const getSaleRecords = async () => {
    try {
      setLoading(true);
      const userId = auth?.user?._id;
      const { data } = await axios.post(`/api/v1/order/sale-records/${page}`, {
        userId,
      });
      setLoading(false);
      setRecords(data.records);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!search) getSaleRecords();
  }, [search]);

  // Load More
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/order/sale-records/${page}`);
      setLoading(false);
      setRecords([...records, ...data?.records]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);

  useEffect(() => {
    if (search) searchProductResult();
  }, [search]);

  // Search Product
  const searchProductResult = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/search-product", {
        search,
      });
      setRecords(data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleProductDetail = (recordId) => {
    setRecordId(recordId);
  };

  return (
    <Layout title={"Transaction Records"}>
      <div className="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Order ID</th>
              <th scope="col" className="text-center">
                Item Qty
              </th>
              <th scope="col" className="text-end">
                Total (RM)
              </th>
              <th scope="col">Payment</th>
              <th scope="col">Reference No.</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <>
                <tr
                  className={
                    recordId === record._id && !showProduct ? "viewDetails" : ""
                  }
                >
                  <td>
                    {moment(record.orderdate)
                      .subtract(8, "hours")
                      .format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>{record.orderid}</td>
                  <td className="text-center">{record.products.length}</td>
                  <td className="text-end">
                    {(Math.round(record.totalorders * 100) / 100).toFixed(2)}
                  </td>
                  <td>{record.paymentmethod}</td>
                  <td>{record.paymentrefno}</td>
                  <td className="text-end">
                    {recordId === record._id && !showProduct ? (
                      ""
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => toggleProductDetail(record._id)}
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    )}
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      recordId === record._id && !showProduct ? "" : "none",
                  }}
                >
                  <td colSpan={7} className="">
                    {record.products.map((product, index) => (
                      <div className="mb-1" style={{ fontSize: "17px" }}>
                        {index + 1}.{" "}
                        <span className="ms-1 me-2">{product.refcode}</span>{" "}
                        {product.name}{" "}
                        <span className="ms-2">x {product.quantity}</span>
                        <span className="ms-3">
                          RM{" "}
                          {(
                            Math.round(product.quantity * product.price * 100) /
                            100
                          ).toFixed(2)}
                        </span>
                      </div>
                    ))}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8} className="text-center">
                {records && records.length < total && !search && (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page + 1);
                    }}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>Load More</>
                    )}
                  </button>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Layout>
  );
};

export default SaleRecords;
