import { createContext, useContext, useEffect, useState } from "react";

const TotalOrderContext = createContext();
const TotalOrderProvider = ({ children }) => {
  const [totalOrder, setTotalOrder] = useState(0);

  useEffect(() => {
    let existingTotalOrder = localStorage.getItem("totalorders");
    if (existingTotalOrder) setTotalOrder(JSON.parse(existingTotalOrder));
  }, []);

  return (
    <TotalOrderContext.Provider value={[totalOrder, setTotalOrder]}>
      {children}
    </TotalOrderContext.Provider>
  );
};

const useTotalOrder = () => useContext(TotalOrderContext);

export { useTotalOrder, TotalOrderProvider };
