import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "./../components/Layout/Layout";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Layout title={"Home Page"}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: 250 }}
      >
        <div className="p-2 m-2 rounded-2 text-center">
          <div className="h2 mt-5" style={{ color: "#2f4483" }}>
            POS System Keningau Bookstore
          </div>
          <div className="h4 mt-4">
            <span className="me-2" style={{ color: "#49759e" }}>
              1 Timotius 2:4
            </span>
            Tuhan menghendaki semua manusia diselamatkan dan sampai pada
            pengetahuan penuh terhadap kebenaran.
          </div>
          <div className="h4 mt-3">
            <span className="me-2" style={{ color: "#49759e" }}>
              Efesus 5:16~17
            </span>
            Tebuslah masa, kerana hari adalah jahat. Oleh itu, jangan menjadi
            jahil; sebaliknya, fahami apa itu kehendak Tuan.
          </div>
          <div className="h4 mt-3">
            <span className="me-2" style={{ color: "#49759e" }}>
              Daniel 11:32b
            </span>
            Tetapi umat yang mengenali Tuhan mereka akan menunjukkan kekuatan
            dan bertindak.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
