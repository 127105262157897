import React from "react";

const CashPaymentForm = ({
  handleSubmit,
  value,
  changeValue,
  setChangeValue,
  closeCashModal,
}) => {
  return (
    <>
      <div className="mt-2 text-center h4 shadow-sm p-3 bg-white rounded">
        Cash Payment
      </div>
      <form className="mt-3 h4">
        <div className="form-group row">
          <label htmlFor="inputPassword" className="col-sm-4 col-form-label">
            Final Total
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={(Math.round(value * 100) / 100).toFixed(2)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-4 col-form-label">
            Change
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              id="staticEmail"
              value={
                changeValue > value
                  ? (Math.round((changeValue - value) * 100) / 100).toFixed(2)
                  : "0.00"
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputPassword" className="col-sm-4 col-form-label">
            Amount
          </label>
          <div className="col-sm-8">
            <input
              type="number"
              className="form-control mt-2 fs-5"
              value={changeValue}
              onChange={(e) => setChangeValue(e.target.value)}
            />
          </div>
        </div>
        <div className="text-end mt-4">
          <button
            type="submit"
            class="btn btn-outline-success"
            onClick={handleSubmit}
          >
            <i class="bi bi-check-lg"></i> OK
          </button>
          <button
            type="submit"
            class="btn btn-outline-danger ms-3"
            onClick={closeCashModal}
          >
            <i class="bi bi-x-lg"></i> Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default CashPaymentForm;
