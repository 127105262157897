import React from "react";

const OrderForm = ({ handleSubmit, value, setValue, placeholder }) => {
  return (
    <>
      <form
        className="row row-cols-lg-auto g-3 align-items-center"
        onSubmit={handleSubmit}
      >
        <div className="col-12">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="inlineFormInputGroupUsername"
              placeholder={placeholder}
              value={value}
              onFocus={(e) => e.target.select()}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-outline-success">
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default OrderForm;
