import React from "react";
import { useAuth } from "../../context/auth";
import { NavLink, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const LoginHeader = () => {
  const [auth, setAuth] = useAuth();

  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{ backgroundColor: "#49759e", height: "50px" }}
      >
        <div className="container-fluid">
          <span
            className="navbar-brand"
            style={{ color: "white", fontSize: "25px" }}
          >
            POS SYSTEM
          </span>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/login"
                  className="nav-link"
                  style={{ color: "white" }}
                >
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LoginHeader;
