import React from "react";

const InitialCashForm = ({
  handleSubmit,
  cashValue,
  setCashValue,
  closeCashModal,
}) => {
  return (
    <>
      <div className="mt-2 text-center h4 shadow-sm p-3 bg-white rounded">
        Initial Cash
      </div>
      <form className="mt-3 h4">
        <div className="form-group row mx-1 ">
          <input
            type="text"
            className="form-control mt-2 fs-5"
            placeholder="RM"
            value={cashValue}
            onChange={(e) => setCashValue(e.target.value)}
          />
        </div>

        <div className="text-end mt-4">
          <button
            type="submit"
            class="btn btn-outline-success"
            disabled={!cashValue}
            onClick={handleSubmit}
          >
            <i class="bi bi-check-lg"></i> Submit
          </button>
          <button
            type="submit"
            class="btn btn-outline-danger ms-3"
            onClick={closeCashModal}
          >
            <i class="bi bi-x-lg"></i> Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default InitialCashForm;
