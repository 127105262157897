import React from "react";

const CategoryEditForm = ({
  title,
  handleSubmit,
  value,
  setValue,
  placeholder,
}) => {
  return (
    <>
      <div className="text-center">Edit {title}</div>
      <form className="align-items-center" onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              defaultValue="option1"
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              Active
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              defaultValue="option2"
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              Inactive
            </label>
          </div>
        </div>
        <div className="text-end">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default CategoryEditForm;
