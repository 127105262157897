import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import CategoryForm from "../../../components/Form/CategoryForm";
import axios from "axios";
import { Modal } from "antd";
import CategoryEditForm from "../../../components/Form/CategoryEditForm";

const AdminSubcategoryList = () => {
  const [name, setName] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [updatedName, setUpdatedName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  // Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/api/v1/subcategory/new-subcategory", {
        name,
      });
      if (data?.success) {
        getAllSubcategory();
        setName("");
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Get All Subcategories
  const getAllSubcategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/subcategory/all-subcategory");
      if (data?.success) {
        setSubcategories(data?.subcategoryList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSubcategory();
  }, []);

  // Update Subcategory
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `/api/v1/subcategory/update-subcategory/${selectedItem._id}`,
        { name: updatedName }
      );
      if (data?.success) {
        setSelectedItem(null);
        setUpdatedName("");
        setModalVisible(false);
        getAllSubcategory();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Subcategory"}>
      <div className="container-fluid m-3">
        <div className="row">
          <div className="p-3 w-50">
            <CategoryForm
              handleSubmit={handleSubmit}
              value={name}
              setValue={setName}
              placeholder="Enter New Subcategory"
            />
          </div>
          <div className="w-100">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col" className="text-center">
                    Status
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {subcategories.map((data, key) => (
                  <>
                    <tr key={key}>
                      <td key={data.subcategoryId}>{data.subcategoryId}</td>
                      <td>{data.name}</td>
                      <td className="text-center">
                        {data.status === 1 ? (
                          <i
                            class="bi bi-check-lg"
                            style={{ color: "green" }}
                          ></i>
                        ) : (
                          <i class="bi bi-x-lg" style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td className="text-end">
                        <button
                          className="btn btn-outline-primary btn-sm border-0 ms-2"
                          onClick={() => {
                            setModalVisible(true);
                            setUpdatedName(data.name);
                            setSelectedItem(data);
                          }}
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            onCancel={() => setModalVisible(false)}
            open={modalVisible}
            footer={null}
          >
            <CategoryEditForm
              value={updatedName}
              setValue={setUpdatedName}
              handleSubmit={handleUpdate}
              title={"Subcategory"}
            />
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default AdminSubcategoryList;
