import React, { useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { useAuth } from "../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const AdminCreateUser = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState(0);
  const [status, setStatus] = useState(0);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/api/v1/user/new-user", {
        name,
        role,
        status,
        username,
        code,
        password,
      });

      if (data?.success) {
        navigate("/admin/users");
      } else {
        console.log(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Create New User"}>
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-sm-center h-100">
            <div className="col-md-6">
              <div className="text-center my-4">
                <h1 className="fs-4 card-title fw-bold">Add New User</h1>
              </div>
              <div className="card shadow-lg">
                <div className="card-body p-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="name">
                        Name
                      </label>
                      <input
                        id="name"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control"
                        name="name"
                        required
                      />
                      <div className="invalid-feedback">Name is invalid</div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="username">
                        Username
                      </label>
                      <input
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                        className="form-control"
                        name="username"
                        required
                      />
                      <div className="invalid-feedback">
                        Username is invalid
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-muted" htmlFor="username">
                        Code
                      </label>
                      <input
                        id="code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        type="text"
                        className="form-control"
                        name="code"
                        required
                      />
                      <div className="invalid-feedback">Code is invalid</div>
                    </div>

                    <div className="mb-3">
                      <div className="mb-2 w-100">
                        <label className="text-muted" htmlFor="password">
                          Role
                        </label>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="admin1"
                            value={1}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor="admin1">
                            Admin
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="cashier2"
                            value={2}
                            onChange={(e) => setRole(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="cashier2"
                          >
                            Cashier
                          </label>
                        </div>
                      </div>
                      <div className="invalid-feedback">Role is required</div>
                    </div>
                    <div className="mb-3">
                      <div className="mb-2 w-100">
                        <label className="text-muted" htmlFor="password">
                          Status
                        </label>
                      </div>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="active"
                            value={1}
                            onChange={(e) => setStatus(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor="active">
                            Active
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            id="inactive"
                            value={2}
                            onChange={(e) => setStatus(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inactive"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                      <div className="invalid-feedback">Status is required</div>
                    </div>
                    <div className="mb-3">
                      <div className="mb-2 w-100">
                        <label className="text-muted" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        name="password"
                        required
                      />
                      <div className="invalid-feedback">
                        Password is required
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-outline-primary ms-auto mx-5"
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn btn-outline-secondary ms-auto"
                        onClick={() => navigate("/admin/users")}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="text-center mt-5 text-muted">
                Copyright © 2023 Gospel Book Centre PLT
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AdminCreateUser;
