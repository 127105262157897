import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaleProductReport from "../../../components/Form/SaleProductReport";
import { Modal } from "antd";
import { Checkbox } from "antd";

const AdminReportList = () => {
  const [reports, setReports] = useState([]);
  const [showProduct, setShowProduct] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [allTotal, setAllTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [visibleProdcutModal, setVisibleProductModal] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [checked, setChecked] = useState([]);

  const navigate = useNavigate();

  // Get Active Cashier
  const getActiveCashier = async () => {
    try {
      const { data } = await axios.get("/api/v1/report/get-active-cashier");
      if (data?.success) {
        setCashiers(data?.cashierList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActiveCashier();
  }, []);

  const toggleProductDetail = (recordId) => {
    setRecordId(recordId);
  };

  // Convert Start and End Date
  const getStartDate = moment(startDate).startOf("day").toDate();
  const getEndDate = moment(endDate).endOf("day").toDate();

  // Search Order Record By Date
  const searchOrderByDateResult = async () => {
    try {
      const { data } = await axios.post("/api/v1/report/search-order-date", {
        getStartDate,
        getEndDate,
      });
      setReports(data?.records);
      setAllTotal(data.getAllTotal[0].totalorders);
      setProducts(data?.products);
      setOpenSearch(true);
    } catch (error) {
      console.log(error);
      setAllTotal(0);
    }
  };

  const closeProductReportModal = (e) => {
    e.preventDefault();
    setVisibleProductModal(false);
  };

  // Filter by Cashier
  const handleCashierFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };

  useEffect(() => {
    if (checked.length) filterCashier();
  }, [checked]);

  // Get Filtered Cashier
  const filterCashier = async () => {
    try {
      const { data } = await axios.post("/api/v1/report/filter-cashier", {
        checked,
        getStartDate,
        getEndDate,
      });
      setReports(data?.records);
      setAllTotal(data.getAllTotal[0].totalorders);
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout title={"Transaction Report"}>
      <div className="col-md-12">
        <div
          className="g-3 align-items-center ms-2"
          style={{ display: "flex" }}
        ></div>
        <div className="d-flex justify-content-between">
          <div className="d-flex mx-2 mt-4 mb-3">
            <span className="me-2 fw-bold">Start Date :</span>
            <DatePicker
              selected={startDate}
              dateFormat={"yyyy-M-dd"}
              onChange={(date) => setStartDate(date)}
            />
            <span className="ms-3 me-2 fw-bold">End Date :</span>
            <DatePicker
              selected={endDate}
              dateFormat={"yyyy-M-dd"}
              onChange={(date) => setEndDate(date)}
            />
            <button
              type="button"
              class="btn btn-primary mx-3 btn-sm"
              onClick={searchOrderByDateResult}
            >
              Submit
            </button>
          </div>

          <div className="mx-2 mt-4 mb-3 fw-bold fs-5">
            <button
              type="button"
              disabled={!openSearch}
              class={!openSearch ? "btn btn-outline-info" : "btn btn-info"}
              onClick={() => setVisibleProductModal(true)}
            >
              Product Sale Details
            </button>
          </div>
          <div className="mx-2 mt-4 mb-3 fw-bold fs-5">
            Total Sale: RM {(Math.round(allTotal * 100) / 100).toFixed(2)}
          </div>
        </div>
        <div className="fw-bold mx-2 mb-3">
          <span className="me-4">Cashier :</span>
          <span>
            {cashiers.map((c) => (
              <span className="me-2">
                <Checkbox
                  disabled={!openSearch}
                  key={c._id}
                  onChange={(e) => handleCashierFilter(e.target.checked, c._id)}
                >
                  {c.name}
                </Checkbox>
              </span>
            ))}
          </span>
        </div>

        <table className="table mt-2">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Cashier</th>
              <th scope="col">Order ID</th>
              <th scope="col" className="text-end">
                Total (RM)
              </th>
              <th scope="col">Payment</th>
              <th scope="col">Reference No.</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reports.map((data) => (
              <>
                <tr
                  className={
                    recordId === data._id && !showProduct ? "viewDetails" : ""
                  }
                >
                  <td>
                    {" "}
                    {moment(data.orderdate)
                      .subtract(8, "hours")
                      .format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>{data.cashier.name}</td>
                  <td>{data.orderid}</td>
                  <td className="text-end">
                    {(Math.round(data.totalorders * 100) / 100).toFixed(2)}
                  </td>
                  <td>{data.paymentmethod}</td>
                  <td>{data.paymentrefno}</td>
                  <td className="text-end">
                    {recordId === data._id && !showProduct ? (
                      ""
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => toggleProductDetail(data._id)}
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    )}
                  </td>
                </tr>
                <tr
                  style={{
                    display:
                      recordId === data._id && !showProduct ? "" : "none",
                  }}
                >
                  <td colSpan={7} className="">
                    {data.products.map((product, index) => (
                      <div className="mb-1" style={{ fontSize: "17px" }}>
                        {index + 1}.{" "}
                        <span className="ms-1 me-2">{product.refcode}</span>{" "}
                        {product.name}{" "}
                        <span className="ms-2">x {product.quantity}</span>
                        <span className="ms-3">
                          RM{" "}
                          {(
                            Math.round(product.quantity * product.price * 100) /
                            100
                          ).toFixed(2)}
                        </span>
                      </div>
                    ))}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        onCancel={() => setVisibleProductModal(false)}
        footer={null}
        open={visibleProdcutModal}
        width={1000}
      >
        <SaleProductReport
          products={products}
          closeProductReportModal={closeProductReportModal}
        />
      </Modal>
    </Layout>
  );
};

export default AdminReportList;
