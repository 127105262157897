import React from "react";
import moment from "moment";

const SummaryReport = ({
  startDate,
  endDate,
  initialCash,
  systemTotal,
  closeSummaryModal,
}) => {
  return (
    <>
      <div className="mt-2 text-center h5 shadow-sm p-3 bg-white rounded">
        Summary Report
      </div>
      <table className="table">
        <tbody>
          <tr>
            <td>Start Time</td>
            <td>
              {moment(startDate)
                .subtract(8, "hour")
                .format("DD/MM/YYYY HH:mm:ss")}
            </td>
          </tr>
          <tr>
            <td className="border-bottom border-primary">End Time</td>
            <td className="border-bottom border-primary">
              {moment(endDate)
                .subtract(8, "hour")
                .format("DD/MM/YYYY HH:mm:ss")}
            </td>
          </tr>
          <tr>
            <td>Initial Cash</td>
            <td>{(Math.round(initialCash * 100) / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td className="border-bottom border-success">Sales</td>
            <td className="border-bottom border-success">
              {(Math.round((systemTotal - initialCash) * 100) / 100).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{(Math.round(systemTotal * 100) / 100).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div className="text-end mt-4">
        <button
          type="submit"
          class="btn btn-outline-danger ms-3"
          onClick={closeSummaryModal}
        >
          <i class="bi bi-x-lg"></i> Close
        </button>
      </div>
    </>
  );
};

export default SummaryReport;
