import React from "react";
import moment from "moment";

const ProductSaleDetails = ({ productName, saleRecords, closeModal }) => {
  return (
    <>
      <div className="mt-2 text-center h5 shadow-sm p-3 bg-white rounded">
        Sale Records: {productName}
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Order ID</th>
            <th>Cashier</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Payment</th>
            <th>Ref</th>
          </tr>
        </thead>
        <tbody>
          {saleRecords != ""
            ? saleRecords[0].orders.map((data) => (
                <tr>
                  <td>
                    {moment(data.orderdate)
                      .subtract(8, "hours")
                      .format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>{data.orderid}</td>
                  <td>{data.name}</td>
                  <td>{data.quantity}</td>
                  <td>{(Math.round(data.price * 100) / 100).toFixed(2)}</td>
                  <td>{data.paymentmethod}</td>
                  <td>{data.paymentrefno}</td>
                </tr>
              ))
            : ""}
        </tbody>
      </table>
      <div className="text-end mt-4">
        <button
          type="submit"
          class="btn btn-outline-danger ms-3"
          onClick={closeModal}
        >
          <i class="bi bi-x-lg"></i> Close
        </button>
      </div>
    </>
  );
};

export default ProductSaleDetails;
